/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "./layout-ostendi.scss"
import "./fonts.scss"
import "./aos.css";

const Layout = ({ children }) => {
  const isBrowser = typeof window !== "undefined";
  const AOS = isBrowser ? require("aos") : undefined;
  AOS && AOS.init();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
        },
      }
    }
  `)

  return (
    <>
      <Header title={data.site.siteMetadata.title} link={'http://ostendi.pl'}/>
      <main>{children}</main>
      <footer>
        <div className={'footer-container'}>
            Administratorem przekazanych przez Ciebie danych osobowych będzie Ostendi Global Sp. z o.o. z siedzibą w Warszawie przy ul. Filtrowej 65/50, 02-055 Warszawa, e-mail: kontakt@ostendi.pl. Informacje o prawach osób, których dane osobowe są przetwarzane przez Ostendi Global Sp. z o.o. ( dalej „Ostendi”), są tutaj. Dane osób kierujących do Ostendi prośbę o przekazanie przykładowego raportu, takiej jak imię i nazwisko nadawcy, adres e-mail, numer telefonu, stanowisko, będą przetwarzane przez Ostendi na potrzeby udzielania odpowiedzi i prowadzenia dalszej korespondencji oraz załatwienia sprawy, której korespondencja dotyczy. Podstawą przetwarzania danych jest art. 6 ust. 1 lit b) Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE oraz przepisy prawa polskiego.
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
