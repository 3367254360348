import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/ostendi-logo.png";
import Picture from "./picture";

const Header = ({ title, link }) => (
  <header data-aos="fade-down">
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Picture path={Logo} alt={title} size={180}/>
    </a>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
